<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card elevation="8" class="mx-auto my-12 popup-background">
        <v-card-text class="py-5 px-10">
          <div class="text-h5 primary--text text-center mb-10">
            ชำระเงิน
          </div>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class=" text-left">
              <div>ราคารวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.total_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ส่วนลดรวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.total_discount | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <!-- <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ราคาหลังหักส่วนลด</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{
                (data.total_price - data.total_discount) | numeral('0,0.00')
              }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ภาษีมูลค่าเพิ่ม 7%</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.vat_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6">
            <v-col cols="4" class=" text-left">
              <div align="left" class="mb-3">หัก ณ ที่จ่าย 3%</div>
            </v-col>
            <v-col cols="2" class=" text-right">
              <span>{{ data.withholding | numeral('0,0.00') }}</span>
            </v-col>
          </v-row> -->
          <v-spacer></v-spacer>
          <v-row justify="center" class="text-h6 red--text">
            <v-col cols="4" class=" text-left">
              <div class="mb-3">จำนวนเงินรวมทั้งสิ้น</div>
            </v-col>
            <v-col cols="2" class=" text-right">
              <span>{{ data.net_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="12 mb-1" align="center">
              <!-- <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 1 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 1"
                ><v-icon left>mdi-qrcode-scan</v-icon>โอนผ่าน QR CODE</v-btn
              >

              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 2 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 2"
                ><v-icon left>mdi-credit-card</v-icon>ชำระผ่านบัตรเครดิต</v-btn
              > -->

              <v-btn
                class="mr-2"
                :color="form.payment_type == 3 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 3"
                >โอนผ่านเลขบัญชี</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn outlined class="px-5 mr-10" color="red" @click="confirm()" :loading="loading" :disabled="loading"
            >ยืนยันการชำระเงิน</v-btn
          >
          <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
            >ปิด</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog persistent scrollable max-width="600" v-model="dialogSuccess">
      <Success :item_id="form.bill_id" @onClose="dialogSuccess = false">
        <!--<PrintDocument :type="docType" :data="getDataPrint.main_bill" :data2="getDataPrint.split_bill" ref="printDocument">
          <v-btn class="px-5 mr-10" color="primary" elevation="0">ใบเสนอราคา
            <v-icon right>mdi-printer</v-icon>
          </v-btn>
        </PrintDocument>  -->
      </Success>
    </v-dialog>
  </v-row>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import Success from '@/views/backoffice/backoffice/receipt/components/Success.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import * as moment from 'moment';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    Success,
    PrintDocument,
    DatePicker,
    TimePicker
  },


  data: () => ({
    dialogSuccess: false,
    form: {
      bill_id: '',
      net_price: 0,
      total_price: 0,
      total_discount: 0,
      vat_price: 0,
      is_withholding: false,
      withholding: 0,
      payment_type: 3
    },
    data:{},
  }),

  async created() {
    this.form.bill_id = this.$route.params.id;
    await this.getData();
  },
  methods: {
    close() {
      this.$router.push({name: 'backoffice-receipt-update',params: { _id: this.form.bill_id }});
    },

    onChangeTime(val, kayName) {
      this.form[kayName] = `${val}:00`;
    },

    async confirm() {
      console.log(this.form);
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {noTimestamp: true})
            };
            await this.$axios.post(`${this.$baseUrl}/backend/bill/pay_bill`, body)
              .then(res => {
                console.log('success');
                this.form.bill_id = res.result;
                this.dialogSuccess = true;
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      let body = {
        token: this.$jwt.sign({ bill_id: this.$route.params.id },this.$privateKey,{ noTimestamp: true })
      };
      await this.$axios.post(`${this.$baseUrl}/backend/bill/get_by_id`, body)
        .then(async res => {
          this.data = { ...res.result };
          this.form.total_price = this.data.total_price;
          this.form.total_discount = this.data.total_discount;
          this.form.vat_price = this.data.vat_price;
          this.form.is_withholding = this.data.is_withholding;
          this.form.withholding = this.data.withholding;
          this.form.net_price = this.data.net_price;
          this.form.payment_date = moment().format('YYYY-MM-DDTHH:mm:ss');
          this.form.payment_time = moment().set({
            hour: parseInt(moment(this.form.payment_date).format('HH')),
            minute: parseInt(moment(this.form.payment_date).format('mm'))
          }).format('HH:mm');
          console.log(this.form)
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>
